// ./
import { parseDataResponse, parseStackedDataResponse } from './response.parser'

// Classes
import { JoiManager }       from '@/Classes/Network/JoiManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Components
import BasicHeader from '@/Components/Global/BasicHeader/template.vue'
import DataTable   from '@/Components/Global/DataTable/template.vue'
import SidePanel   from '@/Components/Global/SidePanel/template.vue'
import SubUserForm from '@/Components/Modules/1/SubUserForm/template.vue'

// Components (Refs)
import { BasicHeaderRef } from '@/Components/Global/BasicHeader/component'
import { DataTableRef }   from '@/Components/Global/DataTable/component'
import { SidePanelRef }   from '@/Components/Global/SidePanel/component'
import { SubUserFormRef } from '@/Components/Modules/1/SubUserForm/component'

// Constants
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Component }   from '@/Constants/Global/Component'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'
import { Module1 }     from '@/Constants/Modules/Module1'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View12 = VueMixins(MixinBase, MixinComponent, MixinFetch,MixinResponsive).extend({
	name: VueRouter.Modules.View12.NAME,

	components: {
		BasicHeader,
		DataTable,
		SidePanel,
		SubUserForm
	},

	data: function() {
		return {
			states: {
				action: Component.Actions.INSERT,
				dataTableParser:parseDataResponse,
				documentToUpdate: undefined as any,
				showDataTable: true,
				showSubUserForm: false,
				preventDefaultStacked:true
			}
		}
	},

	mounted: function() {
		this._initComponents()
		this._initPermissions()
		this._sidePanel.setItems(Module1.M11.SidePanelItems)
		this._dataTable.setStates<DataTableRef['states']>({preventDefaultStacked:true})
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		},

		_subUserForm: function(): SubUserFormRef {
			return this.$refs.subUserForm as SubUserFormRef
		}
	},

	methods: {
		_fetchOperators: function(page = 1) {			
			const _idStorages = Store.getters.getStoredUser?.storages
			Store.dispatch('fetchAllStoragesOperators', { _idStorages, itemsPerPage: this._dataTable.itemsPerPage, page  })
			.then(() => this._initDataTable())
			.catch((error: any) => DevelopmentTools.printError(error.response))
		},

		_initComponents: function(){
			this._initDataTable()
			this._fetchOperators()
		},

		_initDataTable: function() {
			const data = Store.getters.getStoredOperators
			if (data?.length > 0) {
				const dataResponse = data[0]?.data
				const totalPage = data[0].totalPages
				const { stacked } = this._dataTable.states

				this.setStates<View12Ref['states']>({ dataTableParser: stacked ? parseStackedDataResponse : parseDataResponse })
				const { fields, items, actions } = this.states.dataTableParser(dataResponse)
				this._dataTable.updateElementsAndPagination(totalPage, fields, items, actions)

				// Aplicar Orden para Columna especifica.
				this._dataTable.updateElementsAndPagination(totalPage, fields, items, actions)
				this._dataTable.resetEmptyText()
				this._dataTable.sortOrder('rut', 'asc')
			}
		},

		_initPermissions: function() {
			this._dataTable.setPermission('ACTION_EDIT', true)
			this._dataTable.setPermission('NEW_BUTTON', true)
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Controlar la Visibilidad de los elementos según el Breakpoint.
			const stacked = breakpoint <= Breakpoints.XLarge
			this._dataTable.setStates<DataTableRef['states']>({stacked})
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._initDataTable()
		},
		
		_toggleComponents: function(bool: boolean) {
			this.setStates<View12Ref['states']>({ showDataTable: bool, showSubUserForm: !bool })
			this._subUserForm.setStates<SubUserFormRef['states']>({ isFetching: false })
		},

		_updateSubUserForm: function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states

			// Datos que provienen de la fila seleccionada.
			const { _idStorage }     = documentToUpdate
			const { name }           = documentToUpdate
			const { pLastName }      = documentToUpdate
			const { mLastName }      = documentToUpdate
			const { position }       = documentToUpdate
			const { licenses }       = documentToUpdate
			const { certifications } = documentToUpdate

			// Datos que se deben autocompletar para actualizar el Operario.
			this._subUserForm._inputRut.setValue(documentToUpdate.rut)
			this._subUserForm.setStates<SubUserFormRef['states']>({
				action: Component.Actions.UPDATE,
				cert: certifications[0].name,
				certExpiration: certifications[0].expirationDate,
				storageSelected: _idStorage,
				license: licenses[0].name,
				licenseExpiration: licenses[0].expirationDate,
				mLastName, name, pLastName, position
			})
		},

		_upsertUser: async function() {
			// Referencias a Componentes y Datos.
			const { action, documentToUpdate } = this.states
			const isInserting = action === Component.Actions.INSERT

			// Datos requeridos para la petición.
			const _idOperator   = documentToUpdate?._idOperator
			const _idStorage    = this._subUserForm.states.storageSelected
			const { mLastName } = this._subUserForm.states
			const { name }      = this._subUserForm.states
			const { pLastName } = this._subUserForm.states
			const { password }  = this._subUserForm.states
			const { position }  = this._subUserForm.states
			const rut           = this._subUserForm._inputRut.getValue()
			const isValid       = true

			// Construir las ultimas dos propiedades como objetos en Arrays.
			const licenses = [{ name: this._subUserForm.states.license, expirationDate: this._subUserForm.states.licenseExpiration }]
			const certifications = [{ name: this._subUserForm.states.cert, expirationDate: this._subUserForm.states.certExpiration }]

			// Objeto con las Propiedades requeridas por la Petición.
			const body = isInserting ? {
				_idStorage, rut, name, pLastName, mLastName, password, position,  licenses, certifications
			} : {
				_idOperator, _idStorage, name, pLastName, mLastName, position, isValid, licenses, certifications
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module1.M12.JoiSchemas.AddOperator : Module1.M12.JoiSchemas.UpdateOperator
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear un' : 'Actualizar el' } Operario`, result.error)

			// Incluir la contraseña al actualizar solo si no está vacia.
			if (action === Component.Actions.UPDATE && password !== '') body.password = password

			// Realizar la Petición al servidor.
			this._subUserForm.setStates<SubUserFormRef['states']>({ isFetching: true })
			const fetchAction = isInserting ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const fetchPath = isInserting ? Server.Routes.Operators.AddOperator : Server.Routes.Operators.UpdateOperator
			const response = await this.doFetch({ action: fetchAction, path: fetchPath, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Agregar el nuevo registro a la tabla.
				const { fields, items, actions } = parseDataResponse(response.data.body)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)

				if (isInserting) {
					// Agregar la respuesta del nuevo Operario al Store.
					Store.commit('addOperator', response.data.body[0])
					this._dataTable.addRow(items[0])	
				}
				else if (action === Component.Actions.UPDATE) {
					// Actualizar la respuesta del nuevo Operario al Store.
					Store.commit('updateOperator', response.data.body[0])
					this._dataTable.updateRow(items[0], '_idOperator')
				}

				this._subUserForm.clear()
				this._toggleComponents(true)
				const mAction = isInserting ? 'Creado' : 'Actualizado'
				this.showToast(`Usuario ${ mAction }`, `Usuario Operario ${ mAction.toLowerCase() } correctamente!`, 'success')
			}
			this._fetchOperators()
		},

		onDTButtonClick: function(key: string, row: any) {
			if (key === 'edit') {
				this.setStates<View12Ref['states']>({ action: Component.Actions.UPDATE, documentToUpdate: row.item })
				this._toggleComponents(false)
				this._updateSubUserForm()
			}
		},

		onDTNewButtonClick: function() {
			this.setStates<View12Ref['states']>({ action: Component.Actions.INSERT })
			this._toggleComponents(false)
		},

		onDTPaginationChanged: async function(page: number) {
			this._fetchOperators(page)
			const data = Store.getters.getStoredOperators
			if (data?.length > 0) {
				const dataResponse = data[0]?.data
				const totalPage = data[0].totalPages
				const { stacked } = this._dataTable.states
				this.setStates<View12Ref['states']>({ dataTableParser: stacked ? parseStackedDataResponse : parseDataResponse })
				const { fields, items, actions } = this.states.dataTableParser(dataResponse)
				this._dataTable.updateElementsAndPagination(totalPage, fields, items, actions)
			}			
		},

		onDTRefreshButtonClick: function() {
			const { currentPage } = this._dataTable
			Store.commit('destroyOperatorsForPage', currentPage)
			this.onDTPaginationChanged(currentPage)
		},

		onSPItemClick: function(item: any) {
			this.$router.push({ name: item.action })
		},

		onSUFButtonClick: function(action: string) {
			if (action === 'submit') {
				this._upsertUser()
			}
			else if (action === 'cancel') {
				this._toggleComponents(true)
				this._subUserForm.clear()
			}
		}
	},
	
	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Storages, '_initDataTable')
	}
})

// Exports
export default View12
export type View12Ref = InstanceType<typeof View12>